body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Circular !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: Circular;
  src: url(./Assets/fonts/circular/circular-light.woff2) format("woff2"),
    url(./Assets/fonts/circular/circular-light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(./Assets/fonts/circular/circular-book.woff2) format("woff2"),
    url(./Assets/fonts/circular/circular-book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(./Assets/fonts/circular/circular-medium.woff2) format("woff2"),
    url(./Assets/fonts/circular/circular-medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(./Assets/fonts/circular/circular-bold.woff2) format("woff2"),
    url(./Assets/fonts/circular/circular-bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(./Assets/fonts/circular/circular-black.woff2) format("woff2"),
    url(./Assets/fonts/circular/circular-black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}
