.SN-header {
  left: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  top: 0 !important;
  padding: .5rem 1rem;
  align-items: center;
  display: flex;
  position: fixed;
  height: 80px;
}

@media (min-width: 992px) {
  .SN-header {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}

.SN-logo-solid {
  width: 60px;
  height: 60px;
  filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(200deg) brightness(103%) contrast(101%);
  -webkit-filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(200deg) brightness(103%) contrast(101%)
}

.SN-logo-liquid {
  width: 60px;
  height: 60px;
  filter: invert(0%) sepia(100%) saturate(7445%) hue-rotate(264deg) brightness(82%) contrast(92%);
  -webkit-filter: invert(0%) sepia(100%) saturate(7445%) hue-rotate(264deg) brightness(82%) contrast(92%);;
}

.SN-logo-liquid {
  
}

.SN-logo-button {
  background: transparent !important;
  border: 0px solid transparent !important;
}

.SN-nav-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SN-nav-content {
  margin-right: 3rem;
  margin-left: 3rem;
  height: 100%;
}

.SN-nav-item-solid {
  color: white !important;
}

.SN-nav-item-liquid {
  color: black !important;
}

.SN-nav-item {
  font-weight: 500;
  margin-top: 3px;
}