.FC_progressbar {
  border-radius: 0px !important;
  height: 0.7rem;
  padding: 0px !important;
}
.bg-FC_progressbar_color {
  background-color: #cf2b37 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.FC_title {
  margin: 0px !important;
  word-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 24px !important;
  font-weight: 800 !important;
  line-height: 1.25em !important;
  color: #484848 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.FC_text {
  margin: 0px !important;
  word-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: #484848 !important;
}

.FC_control_buttons_div {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
  height: 100% !important;
  padding-bottom: 20px;
}

.FC_control_buttons_divider {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: middle !important;
}

.FC_control_button_left {
  display: table-cell !important;
  vertical-align: middle !important;
}
.FC_control_button_right {
  display: table-cell !important;
  vertical-align: middle !important;
}

.FC_label {
  display: block;
  padding-top: 7px;
  padding-bottom: 6px;
  margin-top: 0px !important;
  word-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: #484848 !important;
}

.FC_form_field {
  margin-top: 12px;
}

.FC_field_container {
  overflow: hidden;
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  height: 56px !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  box-shadow: rgb(176, 176, 176) 0px 0px 0px 1px inset !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.FC_field_label {
  position: relative !important;
  flex: 1 1 0% !important;
  padding: 0px !important;
  margin: 0px;
}

.FC_field_ltr {
  height: 100%;
}

.FC_field_wrapper {
  display: flex !important;
  opacity: 1 !important;
  height: 100%;
}

.FC_field {
  height: 100%;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  padding: 0px 12px !important;
  min-height: 1px !important;
  color: inherit !important;
  background-color: transparent !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  appearance: none !important;
  box-sizing: border-box !important;
}

.FC_textarea_field {
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  height: 70px !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  box-shadow: rgb(176, 176, 176) 0px 0px 0px 1px inset !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  padding: 15px;
}

.FC_field_minor_text {
  padding-top: 8px !important;
  color: rgb(113, 113, 113) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding-left: 0px;
}

.FC_form_error_container {
  position: relative !important;
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

.FC_form_error_marker_container {
  height: 100% !important;
  display: table-cell !important;
  vertical-align: top !important;
  border-style: solid !important;
  border-width: 2px 0px 2px 2px !important;
  border-radius: 8px 0px 0px 8px !important;
  padding: 16px 0px 16px 12px !important;
  width: 48px !important;
  background-color: #cf2b37 !important;
  border-color: #cf2b37 !important;
}

.FC_form_error_marker_icon {
  height: 24px;
  width: 24px;
  display: block;
  padding-right: 2px;
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(295deg)
    brightness(110%) contrast(101%);
}

.FC_form_error_result_container {
  display: table-cell !important;
  vertical-align: top !important;
  width: 100% !important;
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(176, 176, 176) !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 0px !important;
  border-radius: 0px 8px 8px 0px !important;
  padding: 20px 8px !important;
}

.FC_form_error_result_text_wrapper {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
}

.FC_form_error_result_text_container {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: top !important;
}

.FC_form_error_result_title {
  margin: 0px !important;
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
}

.FC_form_error_result_text {
  margin: 0px !important;
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
}

.FC_select_input__control {
  position: relative !important;
  cursor: text !important;
  display: flex !important;
  height: auto !important;
  width: 100% !important;
  margin: 0px !important;
  border: none !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  box-shadow: rgb(176, 176, 176) 0px 0px 0px 1px inset !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  padding-left: 2px !important;
}

.FC_select_input__value-container {
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.FC_card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px !important;
  overflow: hidden;
  border: 0 solid !important;
  margin: 0px !important;
}

.FC_file_upload_wrapper {
  width: 100%;
  border-radius: 0px !important;
  height: 100%;
  padding-top: 10px;
}

.FC_button {
  display: inline-block !important;
  margin: 0px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  width: auto !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  padding: 10px 22px !important;
  font-weight: 800 !important;
  border-width: 2px !important;
  border-style: solid !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  background:  transparent !important;
  border-color: transparent !important;
  color: #cf2b37 !important;
}
.FC_button_cipher {
  display: inline-block !important;
  margin: 0px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  width: auto !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  padding: 10px 22px !important;
  font-weight: 800 !important;
  border-width: 2px !important;
  border-style: solid !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  background:  #cf2b37 !important;
  border-color: transparent !important;
  color: rgb(255, 255, 255) !important;
}

.FC_center_content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}