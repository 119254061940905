.sb-wrapper {
  overflow: hidden;
  background-color: #131212;
  display: block;
  position: relative;
  width: 100%;
  min-height: 810px;
  max-height: 926px;
}

.sb-curve {
  width: 100%;
  z-index: 1;
  position: absolute !important;
  bottom: -1px;
  width: 100% !important;
}

.sb-curve:not(:root) {
  overflow: hidden;
}

@media (min-width: 544px) {
  .sb-wrapper {
    min-height: 850px;
  }
}

@media (min-width: 768px) {
  .sb-wrapper {
    height: 94vh;
    min-height: 860px;
  }
}

.sb-image-background {
  width: 100%;
  height: 100%;
  filter: brightness(50%);
  background-image: url("../../Assets/banner/bg.jpg");
  background-size: cover;
}

.sb-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}

.sb-container {
  margin: auto auto;
}

.sb-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sb-title {
  margin-top: 10px;
  font-weight: 800 !important;
  line-height: 6.2rem;
  letter-spacing: -0.025em !important;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 6rem;
  margin-left: 5px;
}

.sb-description {
  line-height: 2.3rem;
  font-size: 2.3rem;
  font-weight: 300 !important;
  letter-spacing: 0.0215em !important;
  color: #ffffff;
  margin-left: 10px;
}

@media (max-width: 991px) {
  .sb-title {
    font-size: 4rem;
    line-height: 3.5rem;
  }
}

.sb-select__control {
  background-color: white !important;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px !important;
  border: 1px solid white !important;
  font-size: 18px !important;
  margin-top: 1px !important;
  padding: 2px !important;
}
